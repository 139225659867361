import React from 'react';

import Layout from 'components/Foundation/Layout';
import SEO from 'components/Foundation/SEO';
import HeroBanner from 'components/HeroBanner';
import Testimonials from 'components/Testimonials';
import Service from 'components/Services';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HeroBanner />
      <Service />
      <hr />
      <Testimonials />
    </Layout>
  );
};

export default IndexPage;
