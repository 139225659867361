import React from 'react';
import PropTypes from 'prop-types';

import Container from 'components/ui/Container';
import Button from 'components/ui/Button';
import TitleSection from 'components/ui/TitleSection';

import * as Styled from './styles';

const Banner = ({ title, subtitle, content, linkTo, linkText }) => (
  <Styled.Banner>
    <div className="text-center py-24">
      <div className="text-6xl text-nxtgenprimary font-bold">{subtitle} </div>
      <div className="text-gray-600 text-2xl mx-auto max-w-4xl py-4">{content}</div>
      <a href={linkTo}>
        <div
          className="px-12 w-48 my-12 py-4 rounded-full text-2xl text-white font-bold mx-auto bg-nxtgenprimary"
          primary
        >
          {linkText}
        </div>
      </a>
    </div>
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default Banner;
